export default
{   "key00":"所有",
    "key0": "首页",
    "key1": "市场",
    "key2": "游戏",
    "key3": "博客",
    "key4": "搜索游戏或商品",
    "key5": "语言 ",
    "key6": "货币",
    "key7": "显示货币仅供显示，交易仍以美元为单位。",
    "key8": "确定",
    "key9": "热门游戏",
    "key10": "所有游戏",
    "key11": "登录",
    "key12": "注册",
    "key13": "赚取积分",
    "key14": "我",
    "key15": "信息",
    "key16": "评论",
    "key17": "已售",
    "key18": "加载更多",
    "key19": "Steam&Switch CDKEY",
    "key20": "为什么选择我们？",
    "key21": "100% 安全交易",
    "key22": "我们致力于为您提供高效、专业、安全的交易服务。我们将为您在平台上的交易过程和相关数据信息提供安全保障，100% 安全，请您放心。",
    "key23": "全天候支持",
    "key24": "我们拥有强大的客户团队和多种咨询方式，可以快速方便地回答您在交易过程中遇到的任何问题，包括售前、售中和售后所需的任何支持。",
    "key25": "全额退款保证",
    "key26": "playsavefristofficial为您提供极具竞争力的商品价格，并将商品高效送达您的账户。如果商品未送达或送达后无法使用，平台承诺 100%退款，并提供 100%资金安全保证。",
    "key27": "价格",
    "key28": "网站导航",
    "key29": "源泉冲击充值",
    "key30": "本海星轨道充填",
    "key31": "FC 25 硬币",
    "key32": "修改",
    "key33": "争吵之星",
    "key34": "联盟计划",
    "key35": "服务条款",
    "key36": "隐私政策",
    "key37": "Cookie 政策",
    "key38": "常见问题",
    "key39": "关注我们",
    "key40": "取消",
    "key41": "账户信息",
    "key42": "头像",
    "key43": "昵称",
    "key44": "电子邮件",
    "key45": "密码",
    "key46": "已设置",
    "key47": "约束",
    "key48": "保存",
    "key49": "付款信息",
    "key50": "注意：请确保填写的地址与您的银行账户/银行卡关联地址完全一致，否则可能会导致付款失败。",
    "key51": "请选择国家",
    "key52": "请选择省份",
    "key53": "输入城市",
    "key54": "输入地址",
    "key55": "输入邮政编码",
    "key56": "输入您的电子邮件",
    "key57": "输入您的手机",
    "key58": "输入全名。.",
    "key59": "输入完整的姓氏",
    "key60": "输入全年",
    "key61": "输入整月",
    "key62": "输入完整的履历。",
    "key63": "输入完整的卡号",
    "key64": "银行卡号",
    "key65": "CVV",
    "key66": "到期日期",
    "key67": "年",
    "key68": "月",
    "key69": "名字",
    "key70": "姓氏",
    "key71": "国家/地区",
    "key72": "选择国家",
    "key73": "选择省份",
    "key74": "城市",
    "key75": "邮政编码",
    "key76": "地址",
    "key77": "电话",
    "key78": "电子邮件",
    "key79": "立即付款",
    "key80": "省",
    "key81": "快速交付",
    "key82": "安全可靠",
    "key83": "交付：即时充值",
    "key84": "销售量",
    "key85": "订购信息",
    "key86": "请仔细检查填写的信息",
    "key87": "节约",
    "key88": "立即充值",
    "key89": "安全保证",
    "key90": "数量",
    "key91": "价格",
    "key92": "綜合服務",
    "key93": "购买历史",
    "key94": "账户设置",
    "key95": "帮助中心",
    "key96": "我的金额",
    "key97": "我的积分",
    "key98": "我的优惠券",
    "key99": "需要信息",
    "key100": "处理中",
    "key101": "退款",
    "key102": "关于我们",
    "key103": "未支付",
    "key104": "这里没有订单",
    "key105": "申请编号",
    "key106": "已付款",
    "key107": "时间",
    "key108": "这里没有订单",
    "key109": "失败",
    "key110": "搜索",
    "key111": "找不到项目",
    "key112": "搜索结果",
    "key113": "前三名推广人",
    "key114": "服务条款",
    "key115": "隐私政策",
    "key116": "Cookie 政策",
    "key117": "开始赚钱",
    "key118": "分享您的链接，每当有用户使用您分享的链接在 PlaySaveFirst.store 上充值游戏或购买游戏币，您就可以赚钱",
    "key119": "促销员排行榜",
    "key120": "成功订单量",
    "key121": "收入",
    "key122": "谁适合参加PlaySaveFirst.store联盟计划？",
    "key123": "PlaySaveFirst.store 致力于为游戏玩家提供优惠的价格和更好的服务。我们相信，与游戏社区中志同道合的人合作，可以扩大 PlaySaveFirst.store 的影响力，为更多游戏爱好者服务。如果您是游戏内容创作者、YouTuber 和社交媒体影响者，不要犹豫，立即加入 PlaySaveFirst.store 联盟计划！",
    "key124": "我从 PlaySaveFirst.store 联盟计划中最多可以赚多少钱？",
    "key125": "PlaySaveFirst.store 联盟计划的推广者可以从每笔销售中获得高达 10% 的利润，而且您的收入没有上限！",
    "key126": "《元心冲击》和《鸿海：星轨》新用户的奖励标准为 10% 老用户的奖励标准为 2",
    "key127": "充值其他游戏或购买游戏币的奖励标准为 5% 回头用户为 1%",
    "key128": "奖励标准如有调整，请以网站公布的最新调整为准，并可能根据业务发展情况进行调整。",
    "key129": "您可能关心的其他问题",
    "key130": "您的奖励收入可在 24 小时内在您的收入详情中查看，每周结算一次，您可以选择提现或直接充值 PlaySaveFirst.store 上的游戏",
    "key131": "您必须通过合法手段进行推广。不允许通过欺诈手段进行推广。通过谷歌广告等方式推广不符合内容创作者的定位，违反公平原则，也是不允许的。PlaySaveFirst.store有权冻结您的资金。.",
    "key132": "由于规则配置的原因，特殊国家的用户可能无法获得奖励。请关注网站上公布的最新调整，这些调整可能会根据业务发展进行调整。",
    "key133": NaN,
    "key134": "用户协议",
    "key135": "关于这些条款",
    "key136": "本协议由您与注册地址为 1 RAFFLES QUAY #49 SINGAPORE 的 GEARUP PORTAL PTE.LTD.（注册地址：1 RAFFLES QUAY #49 SINGAPORE）（以下简称 ‘我们’、’我方’ 或 ‘我方的’）就我方的PlaySaveFirst应用程序或网站（以下简称 ‘服务’ 或 ‘PlaySaveFirst服务’）订立本协议，该服务允许您根据本协议搜索和购买游戏中的虚拟物品和游戏充值（以下简称 ‘虚拟物品’）。您同意，一旦访问我们的服务，您即受本协议以及适用于该服务的任何指南或规则的约束。",
    "key137": "我们可能会通过在我们的应用程序或网站上发布经修订的条款，不时更新本协议和适用指南。您继续使用我们的服务将被视为您接受更新后的协议。",
    "key138": "您同意以电子形式输入这些条款，并同意以电子形式存储与这些条款相关的记录。",
    "key139": "PlaySaveFirst 是一个在线网站，允许您搜索、购买虚拟物品和游戏充值。",
    "key140": "访问我们的服务，即表示您同意任何相应的用户协议和/或条款和条件和/或游戏政策的条款在所有方面均适用于您。",
    "key141": "您必须达到或超过您居住国的法定年龄才能使用本服务，否则您应在父母或成人的监督下使用本服务。",
    "key142": "为了使用PlaySaveFirst服务，您必须通过您的SNS帐号（Line、Twitter或Facebook帐号）登录，并可附加您自己的电子邮件以备不时之需。在购买虚拟物品之前，您必须绑定您的游戏帐号，通过绑定，您授权我们访问和使用某些游戏用户帐号信息，包括但不限于您的公开游戏资料和您的虚拟物品列表。欲了解更多有关我们向您收集的个人数据以及我们如何使用这些数据的信息，请访问我们的隐私政策。",
    "key143": "您同意将尽必要努力保护您的账户信息并对其保密。您同意，未经我们事先同意，您不得将您的账户以及您在本协议下的任何权利或义务转让给他人，也不得与他人共享您的账户信息。",
    "key144": "用户行为和内容",
    "key145": "在使用我们的服务时，您必须遵守您所在司法管辖区的适用法律或法规（包括任何反洗钱/打击资助恐怖主义行为的法律或法规）、行政命令或措施、任何法院的判决或主管当局的决定。如果任何适用法律限制或禁止您使用我们的服务，您应遵守这些限制或停止访问我们的服务。",
    "key146": "您不得是已沦为且不得沦为以下任何一种情况的人：",
    "key147": "有组织犯罪集团的成员；",
    "key148": "与有组织犯罪集团有这种关系的人，这种关系表明有组织犯罪集团 ‘在很大程度 上参与了该人的管理’；",
    "key149": "与有组织犯罪集团有这种关系的人，表明其对有组织犯罪集团的依赖；",
    "key150": "通过向任何有组织犯罪集团提供资金或任何类似行为，与任何有组织犯罪集团合作并参与其维持或运作的人；或",
    "key151": "与有组织犯罪集团有社会犯罪关系的人。",
    "key152": "用户可以通过我们的服务发送、上传、交流、传输或以其他方式提供信息、数据、软件、声音、照片、图形、视频、标签或其他材料（‘内容’）。您对您通过我们的服务提供的任何及所有内容（无论是公开发布还是私下发送）负全部责任。",
    "key153": "您不得提供任何非法、有害、威胁、辱骂、骚扰、侵权、诽谤、粗俗、淫秽、中伤、侵犯他人隐私、仇恨、歧视、色情、露骨或其他内容；",
    "key154": "您不得提供任何侵犯任何一方的专利、商标、商业秘密、版权或其他专有权利的内容；",
    "key155": "您不得使用作弊器、自动化软件（机器人）、黑客、MOD 或任何其他未经授权的第三方软件来修改服务的任何部分或从服务中收集任何信息；",
    "key156": "您不得对我们的服务、他人创建的任何内容或其任何部分的全部或部分内容进行反向工程、源代码提取、修改、反编译、反汇编或制作衍生作品；",
    "key157": "您不得删除或修改与我们的服务相关的任何专利声明、版权声明或其他知识产权信息；",
    "key158": "购买虚拟物品和 GameTopUp",
    "key159": "您有责任阅读并理解您使用PlaySaveFirst服务后的所有交易条款和条件，并对您的行为承担所有责任。",
    "key160": "PlaySaveFirst 可为用户提供必要的支持，以解决因虚拟物品交易和游戏充值而产生的纠纷。",
    "key161": "退款政策",
    "key162": "除非本使用条款或适用法律另有规定，否则在我们服务内的所有购买均为最终购买，不可退款，且任何产品均不提供退款。对于您通过服务进行的任何支付或交易可能导致的任何索赔或损害，我们对您或任何第三方不承担任何责任。",
    "key163": "所有订单在PlaySaveFirst均为最终订单，除非您已全额支付订单款项，但仍未收到订单，否则PlaySaveFirst不会退款。只要订单所包含的虚拟物品（‘购买产品’）尚未交付或部分交付，即可退款。",
    "key164": NaN,
    "key165": "在以下情况下，不予退款：",
    "key166": "买方已核实并确认收到订单。",
    "key167": "在 PlaySaveFirst 网站之外进行的交易。",
    "key168": "买方对根据订单交付的虚拟物品不满意、买方反悔、错误购买虚拟物品和/或买方的计算机不符合最低产品要求。",
    "key169": "对于直接充值中的错误或错误购买，恕不退款。您必须仔细阅读产品描述，确认游戏名称、服务器、账户名称和购买金额准确无误后方可进行最终结账。对于因您的疏忽和/或提供虚假或错误信息而导致的错误订单，我们不承担任何责任。",
    "key170": "欺诈性索赔。对于提出欺诈性索赔的买家，我们保留追究其法律责任并永久暂停其账户的权利。",
    "key171": "在PlaySaveFirst网站购买任何产品，即表示买家理解、承认并接受上述免责条款。",
    "key172": "根据买家使用的付款方式，退款一般需要 14 天才能处理完毕。这是因为银行通常不会立即完成退款，而且资金的授权保留可能需要 14 天才能到期。",
    "key173": "免责声明",
    "key174": "尽管我们努力为您提供准确可靠的服务，但您明确理解并承认我们的服务和信息是在 '原样' 的基础上提供的，不附带任何形式的保证、无论是明示还是暗示，包括但不限于条件保证、不间断使用、适销性、特定用途的适用性、非侵权性、准确性、可靠性或无错误/缺陷。",
    "key175": "在不限制我们在此明确规定的责任或适用法律要求的责任的前提下，您明确理解并同意，我们不对任何间接的、偶然的、特殊的、后果性的或惩戒性的损害承担责任，包括但不限于利润、商誉、使用、数据或其他无形损失的损害（即使我们已被告知此类损害的可能性）、包括但不限于利润损失、商誉损失、使用损失、数据损失或其他无形损失（即使我们已被告知发生此类损失的可能性），我们不承担因以下原因造成的任何间接、附带、特殊、衍生性或惩戒性损害赔偿责任：(i) 无法使用我们的服务；(ii) 通过我们的服务或从我们的服务购买或获得的任何商品、数据、信息或服务或收到的信息或达成的交易导致的采购替代商品和服务的成本；(iii) 未经授权访问或更改您的传输或数据；(iv) 任何第三方在我们的服务中的声明或行为；或 (v) 与我们的服务相关的任何其他事项。在任何情况下，我方的责任总额（无论是合同、侵权、严格责任还是其他责任）均不得超过您在该索赔发生前六（6）个月内向我方支付的费用总额。",
    "key176": "违约和赔偿",
    "key177": "如果我们合理地发现您违反了本协议或任何张贴的指南或规则，我们可能会采取我们认为适当的行动，包括但不限于：(i) 删除任何涉及的内容；(ii) 暂停我们服务中提供的任何功能；(iii) 暂停您的帐户；(iv) 终止您使用我们服务的权利；(v) 对您采取法律行动或向执法机关披露相关信息，以及 (vi) 任何张贴的准则和规则中规定的任何其他行动。",
    "key178": "对于与本协议中所述的您与我方之间的关系有关或由此产生的任何及所有第三方索赔、损失、损害、责任、成本和费用，包括但不限于任何违反本协议的行为，您应向我方作出赔偿并使我方免受损害。您特此同意，我方有权控制针对任何此类索赔、要求或诉讼的法律辩护，包括有权选择我方律师，以及有权妥协或解决任何此类索赔、要求或诉讼。",
    "key179": "知识产权",
    "key180": "PlaySaveFirst及相关标识和名称是我们的商标和/或服务标志。服务中使用的其他商标、名称和徽标是其各自所有者的商标、服务标志或徽标。您不被授予任何上述商标、服务标记或标识的权利或许可。",
    "key181": "服务以及与之相关的所有相关数据、内容和软件均受版权法、商标法、专利法、商业秘密法和/或其他法律的保护，我们保留并保留我们服务的所有权利（包括但不限于知识产权和所有权）。根据本协议的条款和条件以及任何适用的张贴指南或规则，我们特此授予您有限的、可撤销的、不可再许可的和非排他性的许可，以使用和复制我们的服务，但仅限于与我们的服务相关的个人使用。除非此处有明确规定，否则您不得以其他方式复制、分发、向公众传播、提供、改编、公开执行或公开展示我们的服务或其任何改编。如果您违反本协议，本协议授予的许可将自动终止。某些服务可能受某些第三方许可的约束，例如应用程序或我们网站上声明的开放源码许可。在使用某些服务时，请仔细阅读该第三方的许可协议，并确保您遵守该协议中规定的要求。",
    "key182": "杂项",
    "key183": "完整协议",
    "key184": "本协议阐述了您与我们之间的完整协议，并取代您与我们之间可能存在的任何其他先前或同期协议、谅解或协商。",
    "key185": "可分割性",
    "key186": "如果（但仅限于）本协议的任何条款被宣布或认定为非法、不可执行或无效，则贵方和我方均应免除该条款下产生的所有义务，根据贵方和我方的意图和协议，本协议将被视为通过在必要的范围内修改该条款使其合法和可执行，同时保留其意图来进行修订。如果无法做到这一点，则应以其他合法、可执行且能达到相同目的的条款替代。如果本协议的其余部分未受影响且能够实质性执行，则其余部分应在法律允许的范围内执行。",
    "key187": "放弃我们的权利",
    "key188": "我方在任何时候或任何时期未能执行本协议的任何规定，不得解释为我方放弃该规定或此后执行每项该规定的权利。对本协议任何条款或条件的放弃均不具有效力或对我方具有约束力，除非该放弃已在一份书面文件中明确提及本协议并由我方正式签署。",
    "key189": NaN,
    "key190": "联系我们",
    "key191": "如果您对本用户协议或我们的隐私保护措施有任何疑问，请通过 PlaySaveFirst 网站上的 '联系支持' 与我们联系。",
    "key192": "语言",
    "key193": "本协议可翻译成相关地区的当地语言。如果本协议的英文版本与本地化版本发生冲突，应以英文版本为准。",
    "key194": "管辖法律和地点",
    "key195": "除非适用法律另有规定，本协议应受新加坡法律管辖，作为完全在新加坡履行、谈判和执行的协议，不考虑新加坡的法律冲突规则。以任何方式与您使用我们的服务或本协议有关或因使用我们的服务或本协议而产生的任何争议，应由新加坡国际仲裁中心（'SIAC'）进行仲裁。您特此同意新加坡国际仲裁中心的属人管辖权和审判地。如果您是欧洲经济区的居民，您将受益于您所在国家法律的任何强制性规定（包括与解决争议的地点有关的规定）。",
    "key196": NaN,
    "key197": "最后更新日期：2024 年 8 月 26 日",
    "key198": NaN,
    "key199": "欢迎",
    "key200": "本隐私政策向您介绍我们如何收集信息、 使用和共享您的个人信息（有时称为 '个人数据'）。 隐私政策。它还解释了您的法律权利和选择 与您的个人信息相关联。您对我们服务的访问和使用须遵守以下规定",
    "key201": "使用条款",
    "key202": "我们可能会随时更新本隐私政策。在本隐私政策更新后继续使用服务，即表示您承认将根据最新版本处理个人信息。",
    "key203": "跳转到章节：",
    "key204": "您的个人信息",
    "key205": "我们收集的个人信息",
    "key206": "我们如何使用个人信息",
    "key207": "我们如何共享个人信息",
    "key208": "您的隐私权选择",
    "key209": "行使您的&权利",
    "key210": "选择退出广告营销",
    "key211": "Cookies & 类似技术",
    "key212": "家长监护人须知",
    "key213": "地区信息",
    "key214": "针对美国居民的其他信息",
    "key215": "关于我们",
    "key216": "我们收集的个人信息",
    "key217": "个人信息是与您有关的任何信息或数据，如 已识别或可识别的个人。以下是以下类别 我们收集的服务用户个人信息。",
    "key218": "您提供的信息",
    "key219": "根据您与我们互动的方式，我们可能会要求您提供以下一项或多项信息 以下各类信息。我们只有在出于特定目的需要时才会向您收集这些信息，以便正确操作网站。 服务，并且只有在您选择提供的情况下",
    "key220": "身份、联系人口信息（例如：姓名、网名或其他类似的唯一标识符；头衔；出生日期/年龄；国家/地区；政府颁发的身份证明；电子邮件/电话号码/邮政地址；信息/社交账户 ID）",
    "key221": "账单交易数据（账单/送货地址、付款或财务账户详情、详情 有关您与我们的购买和交易的信息）",
    "key222": "账户个人资料信息（您创建的账户的用户名和密码、安全问题和答案、头像、第三方社交媒体或游戏平台账户 ID，以及您通过账户或个人资料共享的其他信息）",
    "key223": "用户生成的内容（内容、项目或资产 您在我们的服务中创建、共享或上传的内容；您的反馈意见、 关于我们服务的评论或意见；您的帖子、评论和 在我们的社交媒体网站上的参与）",
    "key224": "使用数据偏好（关于您如何使用和配置服务的信息，包括您对 cookie 和类似技术的选择；您的游戏设置、语言和兴趣；您表达的其他偏好）。",
    "key225": "调查研究数据（您在用户调查或研究中自愿提供的信息）",
    "key226": "营销偏好（您在以下方面的选择 向您展示或提供的营销/广告、 包括您对接收新闻简报、调查和 其他宣传内容）",
    "key227": "与我们的通信（您发送给我们的电子邮件、聊天和其他信息的详情）",
    "key228": "我们自动收集的信息：我们一般 自动收集以下类型的信息 访问、访问或使用我们的服务。",
    "key229": "位置信息（城市/国家/地区 基于 IP 地址、手机 ID 和 Wi-Fi 连接）。",
    "key230": "设备、网络和技术信息（IP/IP 地址 MAC 地址和其他类似设备标识符；设备型号、 操作系统、地区/语言设置和互联网 服务提供商；屏幕分辨率、帧速率和 ping 值； 堆栈信息（如崩溃报告和内核转储）和服务器 健康指标（如 CPU 和内存使用率、I/O 使用率和负载 平均值）；在...... 服务；其他网络和技术信息）",
    "key231": "我们从第三方收集的信息：在某些情况下，我们可能会从第三方获得有关您的信息。",
    "key232": "社交媒体或游戏平台账户信息，如 在您选择与第三方绑定、连接或登录时由您授权 在我们服务上的账户（我们收到的具体信息 取决于第三方，可能包括您的平台账户 ID、平台名称、个人资料图片、好友列表、 privacy@PlaySaveFirst.store、年龄、国家/地区；审查或调整 平台共享给我们的信息，请参阅您的 在您使用的特定平台中进行账户设置）",
    "key233": "付款信息，当您选择使用 第三方支付处理服务（如散列标识符、 您的付款来自哪个国家/地区，以及 交易详情)",
    "key234": "关于您的广告或营销数据 在我们的服务中、在我们的交流中与我们的内容互动 发送给您，或其他相关网站或应用程序",
    "key235": "敏感信息。请不要向我们发送任何 敏感信息或 '特殊类别数据'，例如 社会安全号码、宗教信仰、政治派别、性取向、性别、宗教信仰 取向、种族/民族信息、犯罪记录或贸易 工会/协会会员资格。",
    "key236": "我们如何使用个人信息",
    "key237": "我们仅将个人信息用于以下目的 收集的信息。根据适用的数据保护法、 公司处理您的个人数据还必须有法律依据 信息。下面，我们将介绍我们在以下方面的目的和法律依据 处理个人信息。",
    "key238": "您的工作",
    "key239": "我们收集什么",
    "key240": "为什么要收集",
    "key241": "法律依据",
    "key242": "使用一项服务（例如：访问一个网站，注册一个 账户)",
    "key243": "身份、联系方式和人口信息",
    "key244": "账单和交易信息",
    "key245": "账户和个人资料信息",
    "key246": "用户生成的内容",
    "key247": "使用数据和偏好",
    "key248": "调查与研究数据",
    "key249": "营销偏好",
    "key250": "与我们的沟通",
    "key251": "地点信息",
    "key252": "设备、网络和技术信息",
    "key253": "游戏信息",
    "key254": "社交媒体或游戏平台账户信息",
    "key255": "付款信息",
    "key256": "广告或营销数据",
    "key257": "运营并向您提供服务，例如:",
    "key258": "使您能够在您的计算机上下载、安装和运行我们的软件。",
    "key259": " 允许您定制自己的偏好，并为您提供 量身定制的体验",
    "key260": "使您能够与其他用户连接和互动",
    "key261": "就服务与您沟通",
    "key262": "排除故障并改进服务和我们的业务、 包括:",
    "key263": "系统调试、故障排除和诊断测试",
    "key264": "对我们的系统和服务进行性能分析",
    "key265": "测试新系统和服务功能",
    "key266": "系统和日志维护",
    "key267": "为您提供技术支持",
    "key268": "促进服务的安全、安保和完整性、 包括",
    "key269": " 识别、预防和打击欺诈、作弊和不法行为 黑客和其他被禁止的行为",
    "key270": "管理社区标准",
    "key271": "M管理不当内容",
    "key272": "回应用户报告",
    "key273": "为了履行与我们的运营有关的法律义务 服务，如",
    "key274": "实施年龄保证和核查措施",
    "key275": "完成 '了解客户' 要求",
    "key276": "回应有效的法律请求",
    "key277": "维护业务账簿和记录",
    "key278": "运营和管理我们的业务，包括",
    "key279": "进行内部审计",
    "key280": "促进合并、收购和其他重组或 重组（包括预期交易）",
    "key281": "履行我们与您签订的合同（使用条款）",
    "key282": "您的同意与以下具体目的有关 收集信息时向您披露的处理方式",
    "key283": "我们的合法权益",
    "key284": "运营、排除故障并改进服务和我们的业务",
    "key285": "促进外部安全、安保和诚信 履行我们与您的合同",
    "key286": "了解我们的用户以及他们如何与服务互动",
    "key287": "对服务进行内部分析",
    "key288": "遵守法律要求或捍卫我们的法律权利",
    "key289": "履行我们的法律义务，提供 服务",
    "key290": " 注册服务帐户或绑定、连接或登录 您的第三方平台账户",
    "key291": "身份、联系方式和人口信息",
    "key292": "账户和个人资料信息",
    "key293": "使用数据和偏好",
    "key294": "与我们的沟通",
    "key295": "地点信息",
    "key296": "设备、网络和技术信息",
    "key297": "游戏信息",
    "key298": "社交媒体或游戏平台账户信息",
    "key299": "使您能够在服务上建立账户/个人资料、 让用户获得可定制的持续体验，以及 就您的账户与您联系",
    "key300": " 遵守与账户创建和相关的法律义务 维护，例如",
    "key301": "执行年龄保证和核查措施",
    "key302": "验证您的身份并保护您的信息",
    "key303": "履行我们与您签订的合同（使用条款）",
    "key304": "设置账户时征得您的同意",
    "key305": "我们运营和改进服务的合法权益，以及 遵守法律要求或捍卫我们的法律权利",
    "key306": " 购买产品或服务（包括数字内容，例如 虚拟物品和虚拟积分）",
    "key307": "身份、联系方式和人口信息",
    "key308": "账单和交易信息",
    "key309": "账户和个人资料信息",
    "key310": "使用数据和偏好",
    "key311": "与我们的沟通",
    "key312": "游戏信息",
    "key313": "履行购买协议，包括处理和 运送您的订单或为您提供访问数字内容的机会",
    "key314": "履行法律义务，维护我们与消费者购买相关的法律权利，例如处理投诉或退款",
    "key315": "履行我们与您签订的合同（购买协议）",
    "key316": "您同意购买",
    "key317": "我们的合法权益，以遵守法律要求或 维护我们的合法权利",
    "key318": "履行我们与您的购买相关的法律义务",
    "key319": "使用或启用定位功能",
    "key320": "地点信息",
    "key321": "提供您要求的基于位置的服务和功能，例如 作为游戏内的地区性活动、锦标赛和比赛",
    "key322": "我们与您签订的合同（使用条款）的履行情况",
    "key323": "在您启用定位功能时征得您的同意",
    "key324": "联系我们获取客户支持",
    "key325": "身份、联系方式和人口信息",
    "key326": "账单和交易信息",
    "key327": "账户和个人资料信息",
    "key328": "用户生成的内容",
    "key329": "使用数据和偏好",
    "key330": "与我们的沟通",
    "key331": "设备、网络和技术信息",
    "key332": "游戏信息",
    "key333": "社交媒体或游戏平台账户信息",
    "key334": "提供与服务相关的客户支持、 包括",
    "key335": " 促进和处理用户的查询、请求和意见、 和投诉",
    "key336": "向您发送您要求的信息",
    "key337": "协助您使用服务",
    "key338": "遵守法律义务，捍卫我们在以下方面的合法权利 与提供服务和客户支持有关",
    "key339": "履行我们与您签订的合同（使用条款）",
    "key340": "您的同意，与您的具体询问或请求有关",
    "key341": "我们了解用户及其使用方式的合法权益 与服务互动并遵守法律要求 或捍卫我们的法律权利",
    "key342": "履行与客户支持相关的法律义务",
    "key343": "订阅我们的通讯或参与调查、 竞赛或促销",
    "key344": "身份、联系方式和人口信息",
    "key345": "账户和个人资料信息",
    "key346": "使用数据和偏好",
    "key347": "调查与研究数据",
    "key348": "营销偏好",
    "key349": "与我们的沟通",
    "key350": "设备、网络和技术信息",
    "key351": "游戏信息",
    "key352": "社交媒体或游戏平台账户信息",
    "key353": "广告或营销数据",
    "key354": "推广我们的服务并通知您有关新版本的信息、 关于我们的更新、优惠、调查和其他新闻",
    "key355": "通知您有关奖励、竞赛和其他活动的信息并对其进行管理 优惠",
    "key356": " 对我们的用户群和用户进行分析和研究 体验，以改进我们的服务",
    "key357": "您在注册接收相关通信时表示同意",
    "key358": "我们的合法利益是改进和推广我们的服务，以及 管理我们与球员的关系",
    "key359": " 在第三方平台上与我们或我们的内容互动（例如 例如Facebook 粉丝页面、Discord 社区）",
    "key360": "身份、联系方式和人口信息",
    "key361": "账户和个人资料信息",
    "key362": "使用数据和偏好",
    "key363": "促进并回应评论、帖子、反馈和评论、 以及有关我们服务的其他信息",
    "key364": " 我们经营、推广和参与的合法权益 向感兴趣的个人介绍我们的服务",
    "key365": " 数据保留： 我们仅在以下情况下保留个人信息 只要是为实现我们收集信息的目的（包括满足任何法律、监管、税务、会计或其他目的）所合理需要的时间，我们都会将信息提供给您。 报告要求。我们可能会将您的个人数据保留更长时间 如果有投诉或有可能提出法律索赔，则应在合理期限内提出。",
    "key366": " 数据安全：我们实施适当的管理、 技术和物理保障措施，以保护您的信息免受以下危害 意外或未经授权的丢失、滥用、访问、披露和更改、 或销毁。如果我们发现您的个人信息在未经授权的情况下被泄露 信息，我们将依法通知您，并采取措施 确保您的信息安全。但是，任何网络传输都不可能完全安全无误；在网上披露任何信息时，请牢记这一点。",
    "key367": "我们如何共享个人信息",
    "key368": "我们不会透露您的详细联系方式（如姓名、电话号码、电话号码或其他信息）、 电子邮件或邮政地址）与独立第三方联系，而无需您的 除非出于本隐私条款所述的有限目的 政策（如执行使用条款、确保服务安全，或法律或法律程序要求我们这样做）。我们不会出售您的信息。",
    "key369": "如下所述，我们与第三方共享个人信息：",
    "key370": "社交媒体和游戏平台。当您选择 与第三方社交媒体或游戏绑定、连接或登录 平台账户，第三方服务可能会收到以下信息 您与我们的服务互动的方式。 隐私政策。(例如，如果您使用 Xbox 帐户登录 我们的游戏，Microsoft 可能会收到您登录的信息 进入游戏）。我们无法控制这些第三方的隐私保护措施。 因此，请务必查看任何第三方的政策。 您使用的服务。",
    "key371": " 服务提供商。我们与以下机构共享您的信息 为以下目的代表我们开展业务的服务提供商 请参阅 '我们如何使用个人信息'）。服务提供商根据我们的以下规定为我们的服务提供支持 指示，并需要安全地处理信息。 (服务提供商的例子包括云服务、支付 处理者、用户研究提供商以及营销和广告 网络）。",
    "key372": "相关公司。我们会出于与本隐私政策一致的目的，与关联公司和子公司共享您的信息。",
    "key373": " 业务转让。如果我们涉及 公司出售、合并、重组、解散或类似事件、 或与此类事件相关的尽职调查，您的信息可能是以下情况的一部分 转让资产。",
    "key374": " 法律要求和预防伤害。我们可以 在我们有理由相信您的信息属于以下情况时披露您的信息 有必要遵守法律、法规或其他法律程序，或 义务。我们可能会披露您的信息，以防止对我们或以下各方造成伤害 我们的用户，或维护我们和我们用户的权利。",
    "key375": " 数据传输：我们的服务提供商、 附属公司和子公司，以及合作伙伴遍布世界各国。 全球。您的个人信息可能会被传送到 在这些国家加工的产品可能不同于在其领土上加工的产品。 您居住的国家。这些国家的数据保护法可能 与您的司法管辖区不同（在某些情况下，还可能 而不是保护）。这些实体承诺处理信息 遵守适用的数据保护法律，并实施 采取适当的安全措施保护您的信息。",
    "key376": "欧洲经济区/英国/瑞士：从这些国家传输个人信息时，我们采取以下保障措施：",
    "key377": '充分性决定：我们依靠 "适当性决定 "将数据传输到加拿大、日本和英国。',
    "key378": "标准合同条款：对于向新加坡和香港等被认为不适当的国家/地区进行的信息传输，我们采用欧盟委员会和英国信息安全委员会发布的标准合同条款。您可以通过联系我们获得这些措施的副本。",
    "key379": "您的隐私权和选择",
    "key380": "行使您的权利",
    "key381": "根据您的居住地，您可能享有以下与您的个人信息相关的部分或全部权利。但是，这些权利并不是绝对的，可能只适用于某些情况。",
    "key382": "访问和可移植性：申请访问或复制您的个人信息。",
    "key383": "删除：删除您的个人信息。",
    "key384": "更正：更正或更新不准确的个人信息。",
    "key385": "反对：反对处理您的个人信息，或要求我们限制处理您的个人信息。",
    "key386": "撤销同意：在适用的情况下，撤销您的同意不会影响在您撤销同意之前已经进行的处理，也不会影响基于其他合法理由对您的个人信息进行的处理。",
    "key387": "投诉：向数据保护机构投诉我们对您个人信息的处理。",
    "key388": "您可以向您居住或工作的欧洲经济区国家或您认为我们违反数据保护法的国家的数据保护机构提出索赔，也可以向英国信息专员办公室提出索赔（如适用）。",
    "key389": '您可以通过 PRIVACY@PlaySaveFirst.store 向我们提交行使隐私权的请求。请在邮件主题中注明 "隐私权请求"。我们不会因为您行使这些权利而对您进行歧视。',
    "key390": "为了验证您的身份并防止欺诈性请求，我们可能会在处理您的请求之前要求您提供有关您的身份以及您与我们互动情况的进一步信息。如果您在我们这里有一个受密码保护的账户，我们可能会通过对您账户的现有身份验证做法来验证您的身份。",
    "key391": "如果我们拒绝您的请求，您可以联系我们对我们的决定提出上诉。",
    "key392": "选择退出广告和营销",
    "key393": "我们与广告和营销提供商合作，提供有关我们服务的广告，并衡量我们在第三方服务上的广告效果。在法律要求的情况下，我们在出于广告和营销目的共享您的信息之前会征求您的同意。您可以随时撤销同意或选择退出，请发送电子邮件至 PRIVACY@PlaySaveFirst.store。",
    "key394": "我们还使用电子邮件和推送通知向您发送有关服务的营销信息。要退出电子邮件信息，请按照电子邮件中的退订说明进行操作，或通过 PRIVACY@PlaySaveFirst.store 联系我们。要禁用推送通知，请在设备设置中选择相关应用程序。",
    "key395": "Cookie 和类似技术",
    "key396": '我们使用 Cookie 和类似技术收集信息，如浏览器类型、在服务上花费的时间、访问的页面、语言偏好和其他流量数据。我们目前不响应浏览器的 "不跟踪 "信号。如需进一步了解我们如何使用 Cookie，或选择退出 Cookie 的收集和使用，请参阅适用于您正在使用的特定服务的 Cookie 政策。',
    "key397": "您的浏览器可能允许您删除所有 Cookie，只允许来自特定网站的 Cookie，或在发送 Cookie 时进行提示。有关如何在常用浏览器中管理 cookie 的说明，请访问以下网站：Internet Explorer、Firefox、Chrome 浏览器、Safari (iOS)、Safari (Mac) 和 Opera。您不得禁用必要的 cookie，否则网站将无法正常运行。",
    "key398": "家长和监护人须知",
    "key399": "我们的服务面向普通用户。对于某些可能吸引年轻玩家的服务，我们会采取措施核实玩家年龄，在处理儿童个人信息时要求获得可核实的父母同意，并实施高隐私默认设置以保护儿童隐私。这些措施还包括",
    "key400": "向家长和监护人提供针对特定服务的隐私声明，说明我们可能会收集、使用和披露其子女的哪些个人信息。",
    "key401": "根据法律要求，在处理儿童个人信息时，寻求可核实的父母同意。",
    "key402": "将对儿童个人信息的处理限制在提供服务所合理需要的范围内。",
    "key403": "提供家长控制功能，以实现对儿童隐私设置的可视性和细粒度控制。",
    "key404": "专门为年轻球员提供隐私信息。",
    "key405": "家长和监护人可随时通过 privacy@PlaySaveFirst.store 与我们联系，撤回对其子女个人信息的进一步处理许可，或要求我们删除我们收集的与其子女账户相关的个人信息。",
    "key406": "如果您认为您的孩子正在使用未经您同意处理其个人信息的服务，请通过 privacy@PlaySaveFirst.store 联系我们。",
    "key407": 'F在本节中，"儿童 "或 "儿童 "指未满当地适用法律规定的数字同意年龄的未成年人。(例如，英国的数字同意年龄为 13 岁）。',
    "key408": "地区信息",
    "key409": "针对美国居民的其他信息",
    "key410": "本节是对上述信息的补充，进一步介绍了我们如何收集、使用和披露我们收集的使用我们服务的美国消费者的个人信息。",
    "key411": "我们对个人信息的收集和使用。如上所述，我们收集个人信息的目的多种多样，但我们收集信息的主要目的是为了提供和运营服务。我们收集以下类别的个人信息：",
    "key412": "标识符（联系信息和独特的个人标识符，包括用户名、privacy@PlaySaveFirst.store、IP 地址、设备标识符和其他类似标识符）；",
    "key413": "加利福尼亚州客户记录法中定义的个人信息（如联系信息以及--如果您选择提供--身体特征或描述）；",
    "key414": "人口统计信息（出生日期/性别）形式的受保护类别特征（如果您选择提供）； 商业信息（购买或消费历史、倾向或偏好）；",
    "key415": "互联网或电子网络活动（与应用程序、游戏或网站使用相关的信息）；",
    "key416": "大致地理位置数据（国家/地区）；",
    "key417": "音频、电子、视觉或类似信息（如个人照片、用户生成的内容或语音聊天）；",
    "key418": "适用法律规定的其他 “个人信息”（如您的信用卡或借记卡信息）；",
    "key419": "从上述任何类别中得出的推断（例如，根据 IP 地址推断出您所在的城市/国家/地区）； ",
    "key420": "与账户登录和访问凭证相关的敏感个人信息。",
    "key421": "我们如何使用个人信息 “部分介绍了收集个人信息的业务和商业目的。我们如何共享个人信息 ” 部分介绍了我们与哪些第三方共享个人信息以及共享的目的。",
    "key422": "您的权利除上述 “行使您的权利” 中所述的隐私权外，您还可能拥有以下权利：",
    "key423": "退出出售的权利：您有权知道我们是否出售您的数据，并有权在我们出售您的数据时选择退出。我们不会出售用户的个人信息。",
    "key424": "选择退出共享行为广告的权利：您有权选择拒绝出于行为广告目的共享您的个人信息。您可以通过应用程序内的客户服务或发送电子邮件至 privacy@PlaySaveFirst.store 联系我们，选择退出共享。",
    "key425": "授权代理人：以书面形式或通过委托书文件指定一名授权代理，代表您提出行使权利的请求。在接受代理人的此类请求之前，我们将要求代理人提供您授权其代表您行事的证明。",
    "key426": '某些浏览器插件提供了通信选择退出偏好信号的选项，例如全球隐私控制（"GPC"）。我们将 GPC 信号视为请求退出定向广告或 "共享 "与您的浏览器或发送 GPC 信号的设备有关的个人信息。如果您未登录账户，则通过 GPC 信号发出的请求不会扩展到任何账户信息，因为我们不知道您的浏览器或设备与账户之间的联系。',
    "key427": "我们不会出于直接营销目的与第三方共享个人信息。",
    "key428": '我们不会有意 "出售 "或 "共享" 16 岁以下未成年人的个人信息。',
    "key429": "删除公开发布的内容。如果您是加州居民并且是服务的注册用户，您可以通过发送电子邮件至 privacy@PlaySaveFirst.store 或联系游戏内客服，要求我们删除您在未满 18 周岁时发布到服务上的内容或信息。请注意，您的请求并不能确保完全或全面删除内容或信息，例如，您的某些内容可能已被其他用户转贴。",
    "key430": "关于我们",
    "key431": '就适用法律而言，Gearup Portal Pte.Ltd. 是通过服务收集的个人信息的 "数据控制方"。我们的邮寄地址是 1 Raffles Quay #49 Singapore 048583。如果您对本隐私政策有任何疑问，请通过我们的邮寄地址或电子邮件 privacy@PlaySaveFirst.store 联系我们。',
    "key432": NaN,
    "key433": "如何登录交易平台？",
    "key434": "使用 PlaySaveFirst.store 链接进入交易平台。点击右上角的登录按钮进行登录。",
    "key435": "您可以使用电子邮件、Google、Line、Facebook、Twitter 或 Apple 帐户登录交易平台。默认情况下，首次登录时您必须注册一个平台账户。",
    "key436": "如何链接到多个社交媒体账户？",
    "key437": "每个社交媒体只能链接到一个平台账户。您可以进入个人中心 -> 帐户设置链接到另一个平台帐户。链接社交媒体账户后，您可以使用任一账户登录交易平台。请注意，将社交媒体账户链接到平台账户是一个不可逆的过程。请注意，将社交媒体账户链接到平台账户是一个不可逆转的过程，您今后将无法取消社交媒体账户的链接。",
    "key438": "如何将帐户链接到我的电子邮件？",
    "key439": '登录交易平台，进入账户信息。查找电子邮件，点击 "链接电子邮件"。',
    "key440": "输入要链接账户的电子邮件地址。确保您可以访问该电子邮件。完成后，点击 “发送”。",
    "key441": " 登录电子邮件，查看收件箱中的验证码电子邮件。如果没有收到，可在 60s 后再次点击 “发送” 按钮。",
    "key442": "打开验证码邮件，找到里面的验证码。返回交易平台，输入验证码，完成电子邮件链接过程。",
    "key443": "目前，交易平台不支持更改链接的电子邮件。每个电子邮件只能链接到一个平台账户。如果您有任何疑问，请访问帮助中心 -> 客户服务与我们联系。",
    "key444": "如何更改语言和货币？",
    "key445": "您可以在网站导航中找到当前设置的语言货币，点击后即可更改设置。",
    "key446": "登录后，您还可以在 “个人中心 - 偏好设置”中更改语言和货币设置。",
    "key447": "交易费用",
    "key448": "直接充值服务：",
    "key449": "除支付手续费外，直接充值服务不收取任何额外费用。充值金额将根据游戏发行商设定的价格进行调整。请参阅充值页面了解实际金额并完成支付。",
    "key450": "项目交易服务：",
    "key451": "卖方费用：卖家需支付售价 12% 的费用。促销期间，费用可能会有所不同，请以实际收取的费用为准。买家费用：0% 充值费用：请查看充值页面。提现费用：请查看提现页面。您可以在充值或提现页面输入金额来计算手续费。",
    "key452": "直接充电时间",
    "key453": "付款成功后，直接充值服务将在 10 分钟内存入您的账户。在高峰期或特殊活动期间，可能会出现延迟或无法送达的情况，但预计会在 24 小时内自动入账或退款。如果您对订单有任何疑问，请联系客户服务。",
    "key454": "如何查询直接充值记录？",
    "key455": "您可以在 “个人中心 - 购买记录 ” 中查看订单状态。如果订单显示成功，您可以登录游戏账户查看相应的资产增加情况。游戏还可能通过弹出式窗口或游戏内电子邮件发送提醒，通知您充值。",
    "key456": "如果输入了直接充值信息怎么办？",
    "key457": "重要提示：对于因用户疏忽、提供虚假或错误信息或任何其他原因造成的错误，交易平台不承担任何责任。订单一旦完成，将无法取消或退款。",
    "key458": "请注意:",
    "key459": "1.请在付款前核对充值信息，包括游戏帐号、用户名和服务器，以免出现充值错误的问题。",
    "key460": "2.由于服务提供商的要求，充值订单一旦完成，将无法取消或退款。您可以重新发起充值，但交易平台不对错误购买问题负责。",
    "key461": "3.如果游戏 ID 输入错误导致充值失败，在确认所提供的信息有误后，订单将自动退款。如果您对直接充值服务订单有任何疑问，请联系客服。",
    "key462": "如果直接充值服务未计入",
    "key463": "账户？",
    "key464": "如直接充值服务未到账，且订单未退款，请提供相关支付成功和未到账证明，并联系客服寻求帮助。如果平台客服核实充值未到游戏账户，将办理退款。",
    "key465": "什么是 KYC 身份验证，为什么我需要完成此流程？",
    "key466": "PlaySaveFirst.store 的 KYC 验证是验证用户身份的支付验证。",
    "key467": "KYC 验证是根据支付卡行业数据安全标准（PCI）和反洗钱金融行动特别工作组（FATF）的要求设计的，当用户支付/取款达到指定规则时就会触发。其核心目标是保护用户的银行卡安全，防止通过代理充值进行洗钱犯罪。",
    "key468": "KYC 验证必须是能够证明您身份的文件，请准备身份证、健康保险卡、驾驶执照、学生证等有效证件。",
    "key469": "请放心，您的个人隐私信息绝对安全，PlaySaveFirst.store 不会收集或披露任何用户隐私。",
    "key470": "我被要求进行 KYC 身份验证，验证过程是否安全？如果是，我提交的文件如何存储和使用？",
    "key471": "我们珍视您对我们提供个人信息的信任。请放心，PlaySaveFirst.store不会出于非营销目的，与其他非关联公司或组织分享、披露或出售在PlaySaveFirst.store网站上收集到的任何个人身份信息（如您的姓名、地址、手机号码或电子邮件地址）。",
    "key472": "KYC 验证失败的原因是什么？",
    "key473": "KYC 是一种实名认证机制。为了确保用户的资产安全，PlaySaveFirst 在充值和提现时要求用户进行 KYC 认证。",
    "key474": "如果 KYC 身份验证失败，原因可能如下： 如果 KYC 身份验证失败，原因可能如下：",
    "key475": "1.您提交的实名认证证书是新签发的证书，证书信息尚未同步到相应的管理中心。",
    "key476": "2.您的证书信息不完整或不合格。",
    "key477": "3.您的身份验证失败。",
    "key478": "如果 KYC 认证失败或达到限制，我该怎么办？",
    "key479": "实名认证给您带来的不便，敬请谅解。您可以按照以下步骤提交个人信息，我们将通过人工审核为您处理。",
    "key480": "点击 “帮助中心--客户服务”。",
    "key481": "反馈分类选择 KYC 认证。",
    "key482": "填写您的姓名、身份证号码和证书类型（身份证或护照）",
    "key483": "上传一张您手持身份证的照片（见照片）",
    "key484": "上传您的 ID 和您的 PlaySaveFirst 信息页面的照片（请查看照片，您必须清楚地看到您的用户名和头像）",
    "key485": "上述信息为KYC验证过程所必需，您的个人信息仅用于安全验证。PlaySaveFirst承诺严格保护您的个人信息。人工验证大约需要1个工作日完成。",
    "key486": "如何使用银行卡充值？",
    "key487": "您可以使用信用卡在交易平台上进行充值。充值渠道运营商可能会对每笔交易收取一定的管理费。在充值页面输入金额时，您可以看到具体的收费标准。",
    "key488": "充值金额只能用于购买物品，不能提现。",
    "key489": "将银行卡链接到您的账户时，请确保以下信息正确无误：",
    "key490": "个人信息，如姓名、国家/地区、详细地址等。这些信息必须与提供给发卡机构或银行的详细信息一致。",
    "key491": "卡号和卡验证码 (CVC)。",
    "key492": "备注：",
    "key493": "*一次性充值上限为 500 美元；用户每天最多可充值 10 次，每月最多可充值 50 次。",
    "key494": "*每个 PlaySaveFirst 账户最多只能添加 5 张不同的银行卡。但是，所有银行卡的持卡人姓名必须相同。",
    "key495": "*如果充值失败，请查看充值页面，了解系统提供的原因。解决问题后再试一次。",
    "key496": "*每天、每周和每月的充值次数均有限制。在进行充值之前，请确保您的银行卡有足够的余额。如果充值失败，请在充值页面查看原因并解决问题。请注意，充值失败也会计入限额。",
    "key497": "*如果您有任何疑问，请联系我们的客户服务部门寻求帮助。",
    "key498": "如何在没有信用卡的情况下充值？",
    "key499": '交易平台支持多种支付方式，包括电子钱包支付、银行转账、便利店支付和其他支付方式。您可以在 "个人中心 "的 "我的钱包 "页面查看可用的支付方式。',
    "key500": "便利店付款有哪些注意事项？",
    "key501": "目前，该平台支持在全家和罗森等便利店付款。输入充值信息后，即可在相应的便利店进行线下支付。",
    "key502": "请注意",
    "key503": "(1) 发起充值并不意味着您已进入购买流程，目前充值资金仅从您的个人余额中扣除，充值成功后，您需要发起新的购买；在此过程中，您喜爱的道具可能会被他人购买，请注意这一点；",
    "key504": '(2）由于是国际支付，便利店付款后充值到账一般需要 3 个小时左右，请耐心等待，如果便利店付款长时间未到账，可以点击 "客户服务 "进行反馈。',
    "key505": "每次充值的最低和最高金额是多少？",
    "key506": "使用 Visa 卡或万事达卡充值的单笔最低金额为 10 美元，最高金额为 5,000 美元。",
    "key507": "充值费用是多少？",
    "key508": "银行将收取一定的交易管理费。更多信息，请访问个人中心 -> 我的钱包，查找充值页面（需要登录）。",
    "key509": "目前，Visa 或万事达卡充值手续费为 3.5% + 0.15 美元。这适用于大多数国家。(目前不支持阿根廷、巴西、哥伦比亚、尼日利亚和新加坡的银行卡）。涉及俄罗斯方面的交易也可能因不明原因而失败）。",
    "key510": "充值错误及解决方法",
    "key511": "充值请求失败时，请仔细检查充值错误的原因。不同错误的解决方案如下。",
    "key512": '(1) 超过单笔充值限额 如果您的充值指令显示 "请勿兑现 "或 "涉嫌欺诈 "等错误信息，则可能是因为充值限额已被超过而触发了安全警报。将单笔充值金额保持在 100 美元以下，然后重试。',
    "key513": "(2) 付款方拒绝交易的其他原因 当充值请求失败时，很可能是因为交易被付款方而不是交易平台拒绝。可能的原因包括:",
    "key514": "您的银行账户余额不足",
    "key515": "您的银行卡受到限制",
    "key516": "有关付款方拒绝交易的可能原因的详细信息，请直接联系付款方。",
    "key517": "在以下情况下，交易平台也可能拒绝您的充值申请：",
    "key518": "您尝试将两个或多个不同的交易平台账户与同一张信用卡关联",
    "key519": "您已达到充值限额 交易平台不收取充值失败交易的任何费用。您可以尝试使用以下方式再次充值：",
    "key520": "更改链接的银行卡",
    "key521": "使用其他浏览器或电脑",
    "key522": "进行多次小额充值",
    "key523": "启用浏览器阻止弹出窗口提醒功能",
    "key524": "如果您对充值交易有任何疑问，请点击 “客户服务 ”与我们联系。",
    "key525": "如何向银行卡取款？",
    "key526": "在取款页面，您可以选择向银行账户取款。在大多数情况下，您的取款申请会在 1 天内得到审核和批准，取款金额会在 1-5 个工作日内到达您的账户。具体所需时间取决于您的银行。",
    "key527": "如果提款失败，请在提款页面查看原因。如果可以，请解决问题后再试一次。",
    "key528": " 此外，由于日本的法规更为严格，日本地区的用户应在退订表中填写以下内容：",
    "key529": "根据付款渠道的要求，请用英文填写您的 “名 ”和 “姓”。",
    "key530": '请填写您的详细地址，包括城镇、街道和门牌号。我们建议您按照以下格式填写地址："XXX 路 XXX 街 XXX 号"。请注意，如果您的街道地址只包含数字，请按以下格式填写地址："XXX镇 1-2-1203"，以免您的订单被拒绝。',
    "key531": "如果选择本地取款，账户名必须为片假名或英文。如果选择 Swift 转账，账户名必须为英文。",
    "key532": "账户名应与您的名和姓相同，并且都必须是持卡人的真实姓名。语言优先级如下：英语 > 片假名。",
    "key533": "提交提款申请后，平台或渠道将对申请进行审核。如果您的提款申请状态变为 “批准”，资金将在 1-5 个工作日内存入您的账户。",
    "key534": "请注意，充值余额不能提现。您只能提取商品销售收入。每次最高提现金额为 2,000 美元；每笔交易均需人工审批。用户每天可提现 5 次，一年内提现总额不得超过 20,000 美元。",
    "key535": "此外，我们正在探索更多提款方式，如礼品卡、手机充值卡等。敬请关注更多更新。",
    "key536": "如何向我的 Payoneer 账户提款？",
    "key537": "Payoneer 是一个国际支付平台，为世界各地的用户提供服务。它支持大多数银行转账和第三方支付方式。",
    "key538": "您可以使用银行卡创建 Payoneer 账户。然后，从交易平台账户向 Payoneer 账户提款，再从 Payoneer 账户向银行卡转账。不过，这需要支付一定的手续费。",
    "key539": "要向您的 Payoneer 账户取款，请按以下步骤操作：",
    "key540": "开设 Payoneer 账户。",
    "key541": "按照 Payoneer 上的说明完成身份验证过程。将您的银行卡或第三方支付方式链接到您的 Payoneer 账户。",
    "key542": "进入交易平台的提款页面，将您的平台账户与 Payoneer 账户连接（需要登录）。",
    "key543": "提交提款申请，等待资金存入您的 Payoneer 账户。",
    "key544": "swift 和 local 有什么区别？",
    "key545": 'Swift" 的汇款方式与 "Local "不同。Swift 是国际电汇，跨境汇款成本高、到账慢。本地汇款用于本地汇款，费用低、到账快。建议您先使用本地模式汇款。',
    "key546": "为什么不能提款？",
    "key547": "交易平台上的每笔取款都将由平台和相关银行进行审查。您的取款申请可能被拒绝的原因包括:",
    "key548": "您在平台上输入的银行信息与您在银行的信息不符",
    "key549": "您的帐户目前受到限制",
    "key550": "如果您对提款有任何疑问，请访问帮助中心 -> 客户服务与我们联系。",
    "key551": "最低提款额是多少？",
    "key552": "银行转账：50 美元",
    "key553": "电子钱包50 美元",
    "key554": "将金额记入我的账户需要多长时间？",
    "key555": "银行转账：1-5 个工作日",
    "key556": "Payoneer: 1 个工作日",
    "key557": "提款费用是多少？",
    "key558": "不同支付渠道的费用：",
    "key559": "Payoneer：1% + 1 美元（最低 2 美元）；实际费用取决于交易金额。",
    "key560": "银行卡：1%+固定手续费（2.5-8 美元，取决于接收银行。我们建议您使用支持本地转账的银行卡进行取款或充值）。",
    "key561": "*如果您的取款申请涉及任何货币兑换，银行可能会使用其实时汇率，并可能收取额外费用。",
    "key562": "*收款银行可能会收取额外费用，如银行手续费或其他费用。更多信息，请咨询您的银行。",
    "key563": "*请注意，交易平台不会对失败的取款请求收取任何费用。任何费用均由支付渠道或银行收取。",
    "key564": "哪些国家/地区支持提款申请？",
    "key565": "平台目前支持银行转账的国家和地区（按字母顺序排列）：",
    "key566": "阿根廷、澳大利亚、奥地利、比利时、波斯尼亚和黑塞哥维那、巴西、保加利亚、柬埔寨、加拿大、智利、哥伦比亚、哥斯达黎加、克罗地亚、塞浦路斯、捷克共和国、丹麦、埃及、爱沙尼亚、芬兰、法国、格鲁吉亚、德国、希腊、危地马拉、匈牙利、冰岛、印度、印度尼西亚、爱尔兰、以色列、意大利、日本、哈萨克斯坦、拉脱维亚、立陶宛、卢森堡、马来西亚、马耳他、墨西哥、摩尔多瓦、摩洛哥、荷兰、新西兰、北马其顿、挪威、巴基斯坦、巴拿马、秘鲁、菲律宾、波兰、葡萄牙、罗马尼亚、俄罗斯、塞尔维亚、新加坡、斯洛伐克、韩国、西班牙、瑞典、瑞士、泰国、土耳其、乌克兰、阿联酋、英国、美国、乌拉圭、越南。",
    "key567": "平台目前支持 Payoneer 取款的国家和地区：",
    "key568": "通常覆盖全球，可能会根据实际情况发生变化。",
    "key569": "7 天",
    "key570": "最后修订 [2024.01.01]",
    "key571": "生效日期 [2024.01.01]",
    "key572": "Cookies 和类似技术政策",
    "key573": '[PlaySaveFirst]（"我们 "或 "我们的"）在[https://PlaySaveFirst.store]（"网站"）上使用cookies和其他类似技术。本Cookies及类似技术政策（以下简称 "CST政策"）是对我们隐私政策的补充，描述了我们如何在网站上使用cookies及类似技术。',
    "key574": "如果您有任何问题，请通过电子邮件 [support@PlaySaveFirst.store] 与我们联系。",
    "key575": "什么是 cookie 和类似技术？",
    "key576": "Cookie 是一种小文件，放置在您的设备上后，可使我们提供某些特性和功能。Cookie 是网站的一项标准功能，允许我们在您的计算机上存储少量有关您访问网站的数据。Cookie 可帮助我们了解网站的哪些方面有用，哪些方面需要改进。",
    "key577": "您可以通过更改浏览器的设置，或更改本网站的特定设置（如本 CST 政策第 3 节所述），选择是否接受 cookie 和类似技术的一般使用。但是，如果您禁用 cookie 和类似技术，您在本网站上的体验可能会降低，某些功能可能无法按预期运行。",
    "key578": "我们使用哪些 cookie 和类似技术？",
    "key579": '下面我们列出了我们用于运营网站的不同类型的 Cookie 和类似技术。Cookie 可由我们直接向您的设备提供（第一方 Cookie），也可由第三方代表我们提供（第三方 Cookie）。Cookie 可以是瞬时的，在您关闭浏览器后即失效（"会话 "cookie），也可以是持久的，在您关闭浏览器后仍保留在您的设备上（"持久 "cookie）。',
    "key580": "必要Cookies",
    "key581": "必要 cookie 是网站运行所必需的。我们使用这些 cookie 来显示网站、管理网络或遵守适用法律。在您使用本网站时，我们始终会设置以下 cookie：",
    "key582": "Cookie 名称",
    "key583": "目的说明",
    "key584": "永久 cookie 还是会话 cookie？持久性 cookie 何时过期？",
    "key585": "第一方（由访问网站设置）还是第三方（跨不同网站）？",
    "key586": "ACCEPT_ESSENTIAL",
    "key587": "该 cookie 用于记录用户是否接受必要的 cookie。",
    "key588": "坚持不懈",
    "key589": "第一方",
    "key590": "接受分析",
    "key591": "此 cookie 用于跟踪用户是否接受分析 cookie。",
    "key592": "ps-token",
    "key593": "我们使用此 cookie 验证您的帐户并确定您何时登录，以便您更方便地访问我们的网站，并向您展示适当的体验和功能。",
    "key594": "安装已领取的优惠券",
    "key595": "此 cookie 用于记录用户是否已领取 PWA 优惠券。",
    "key596": "不再有",
    "key597": "此 cookie 用于记录用户确认信息，以了解无需再次提示的信息。",
    "key598": " 设备 ID（本地存储）",
    "key599": "此 cookie 用于记录设备 ID 以发送通知。",
    "key600": "偏好 Cookies。偏好 Cookies 收集有关您的选择和偏好的信息，使我们能够记住语言或其他本地设置，并相应地定制网站。",
    "key601": "用途描述",
    "key602": "“这个 cookie 是持久性 cookie 还是会话 cookie？持久性 cookie 何时过期？”",
    "key603": "第一方（由访问网站设置）还是第三方（跨不同网站）？指明第三方（法定全称和 cookie 政策的网址）",
    "key604": "登录-隐藏",
    "key605": "此 cookie 用于记录是否隐藏登录框。",
    "key606": "1 天",
    "key607": "保持登录",
    "key608": "此 cookie 用于记录是否选中了 “保持我的登录状态”选项。",
    "key609": "登录通道",
    "key610": "此 cookie 用于记录登录方法。",
    "key611": "折叠式",
    "key612": "此 cookie 用于记录用户是否折叠了左侧菜单。",
    "key613": "inventory-knivesout-toast",
    "key614": "该 cookie 用于记录主页上是否显示了货架上的提示。",
    "key615": "选择语言",
    "key616": "该 cookie 用于记录用户选择的首选语言。",
    "key617": "选择货币",
    "key618": "此 cookie 用于记录用户选择的首选货币。",
    "key619": "公告 ID",
    "key620": "此 cookie 用于记录用户是否关闭了公告。",
    "key621": "充电-投票",
    "key622": "此 cookie 用于记录正在进行的充值订单 ID 和更新订单状态显示。",
    "key623": "结账卡-通知",
    "key624": "该 cookie 用于记录在首次绑定卡片时是否显示了提示。",
    "key625": "检查源轮询",
    "key626": "此 cookie 用于记录正在进行的卡片绑定 ID 的待定查询，并更新卡片绑定状态显示。",
    "key627": "撤销投票",
    "key628": "该 cookie 用于记录正在进行的提款订单的 ID，并更新订单状态显示",
    "key629": "返回顶部",
    "key630": "该 cookie 用于记录用户在充值时填写的表格内容，以便在后续购买时自动填写。",
    "key631": "充值提示",
    "key632": "此 cookie 用于记录用户是否已关闭充值页面提示。",
    "key633": "性能/分析 Cookie。性能 Cookie（包括分析 Cookie）通过（例如）计算访问量和流量来源、显示最受欢迎和最不受欢迎的页面以及跟踪访问者如何在网站上移动，帮助我们了解网站的运行情况、访问者是谁以及他们如何使用网站。这包括有关您如何与我们网站互动的信息，如您浏览的页面、引用和退出页面，以及您访问的日期、时间和长度。我们还可能收集一般位置信息，如您所在的城市或国家（根据您的 IP 地址推断）。",
    "key634": "具体而言，我们使用 Google Analytics，这是 Google 公司（以下简称 “Google”）提供的一项网络分析服务。谷歌使用 Cookie 帮助我们跟踪用户行为（如点击事件、页面浏览和位置信息），以了解和分析用户如何使用我们的网站。Cookie 生成的有关您使用我们网站的信息（包括您的 IP 地址）将被传输并存储在谷歌位于美国的服务器上。有关 Google Analytics Cookie 的更多信息，请参阅 Google 的帮助页面和隐私政策。",
    "key635": "如何管理 cookie 和类似技术？",
    "key636": "您可以通过我们的 Cookie 偏好设置中心随时更改您对第一方 Cookie 的设置，包括撤销您的同意：",
    "key637": "您可以下载并安装谷歌浏览器插件，以防止谷歌分析收集和使用信息。",
    "key638": "您的浏览器可能允许您删除所有 Cookie，只允许来自特定网站的 Cookie，或在发送 Cookie 时进行提示。有关如何在常用浏览器中管理 cookie 的说明，请访问以下网站：Internet Explorer、Firefox、Chrome 浏览器、Safari (iOS)、Safari (Mac) 和 Opera。您不得禁用必要的 cookie，否则网站将无法正常运行。要了解有关 cookie 的更多信息，包括如何查看已设置的 cookie，请访问 www.aboutcookies.org 或 www.allaboutcookies.org。请注意，如果禁用 cookie，网站的所有功能可能无法正常运行。",
    "key639": "切勿跟踪",
    "key640": '不跟踪（"DNT"）是用户可以在某些网络浏览器中设置的一种隐私偏好。DNT 是用户告知网站他们不希望网站或在线服务长期收集其访问网页的某些信息的一种方式。您可以在此了解更多有关 "不跟踪 "的信息。由于网络浏览器整合和激活该功能的方式不同，我们目前不会对这些信号做出响应。',
    "key641": "本 cookie 政策的变更",
    "key642": "本 CST 政策可能会有变动。我们建议您定期查看本 CST 政策，以了解任何修订或修正。",
    "key643": "更换头像",
    "key644": "申请会员计划",
    "key645": "交通联盟协议",
    "key646": "导言",
    "key647": '本《流量联盟协议》以及任何适用的附表，包括但不限于任何奖励附表（统称 "协议"）是您（以下简称 "合作伙伴"）与 GEARUP PORTAL PTE.LTD.(PlaySaveFirst"）拥有和运营的平台（以下简称 "平台"）。本协议规定了 PlaySaveFirst 与合作伙伴之间有关平台提供的流量联盟计划的条款。本协议将适用于合作伙伴，除非全部或部分被PlaySaveFirst与合作伙伴就相同主题签署的书面协议所取代。',
    "key648": "广告宣传机会",
    "key649": '在流量联盟计划下，PlaySaveFirst的主要职责是为合作伙伴提议和设置广告活动机会（每个 "活动"），并邀请合作伙伴参与此类活动。每个新活动的详情，包括适用的奖励表（定义见下文）将在平台网站上列出。为免生疑问，乐捐吧不保证任何机会或活动，且每个机会均以非独家方式与合作伙伴共享。',
    "key650": "跟踪促销和付款",
    "key651": '合作伙伴成功为平台带来流量和/或推荐用户购买平台的商品和服务，将获得奖励。适用的奖励计划应在本协议的附表（"奖励附表"）中列出，该附表可在 [网站] 上找到。奖励明细表应规定合作伙伴有权赚取奖励明细表中规定的某些平台虚拟信用点数（"支付"） 的行动（"行动"）和合格参数（"条件"）。支付须经平台自行决定批准，即 "行动 "已完成且 "条件 "已满足。平台可根据业务需要自行决定随时更新奖励计划。所有与行动追踪和支付计算相关的追踪和支付处理设施应由乐搭提供和承担。除非获得乐捐吧的明确书面授权，或在奖励计划表中另行规定，否则合作伙伴不得使用材料以下列方式推广活动： (a) 提供非通过预定消费者（"最终用户"）行动获得的线索（例如，通过使用电话簿或其他方式获得的线索）； (b) 提供非通过预定消费者（"最终用户"）行动获得的线索（例如，通过使用电话簿或其他方式获得的线索）。(b) 使用虚假重定向、自动软件或其他机制生成行动；(c) 非善意行动，例如使用任何自动设备、机器人、Iframes 或隐藏框架的行动；(e) 推荐在类似平台和游戏上购买/交易行为不真实或虚假的用户，例如，已知有高额退款历史或其他异常行为的用户。',
    "key652": "知识产权与保密",
    "key653": "许可：根据本条款，乐捐吧授予合作伙伴在每次活动期间使用乐捐吧提供的任何资料（“资料”）的可撤销、非独占、不可转让、全球范围、免版税的许可，但仅限于履行本条款义务所需的范围。",
    "key654": "知识产权：“知识产权”是指商标（无论注册与否，以及与此相关的所有商誉）、未注册和已注册的外观设计、版权、数据库权利、计算机软件权利、域名、保密信息权利（定义见下文）以及所有其他知识产权（无论现在存在还是将来产生）。在合作伙伴接受本协议生效日期之前存在的所有知识产权，应属于在该日期之前拥有这些权利的一方。PlaySaveFirst应拥有平台网站和平台、材料以及所有活动（包括其适用的奖励表）中存在的知识产权的所有权利、利益和所有权，任何合作伙伴承认并同意其不得获取或主张PlaySaveFirst知识产权的任何所有权。",
    "key655_1":"机密信息： ",
    "key655": '"机密信息 "指所有机密信息和数据，无论是有形还是无形形式，如商业秘密、财务和技术信息、客户、价格、折扣和利润。从乐投吧和/或另一合作伙伴（"披露方"）处接收机密信息的合作伙伴（"接收方"）同意：(a) 对所有机密信息保密，并以与接收方保护其自身机密信息相同的谨慎程度保护机密信息；(b) 仅在本协议允许的范围内使用机密信息；(c) 除非出于本协议的目的，不得复制或以其他方式拷贝此类机密信息；以及 (d) 仅限于为本协议之目的需要了解此类机密信息并受书面约束保护此类机密信息的雇员和代理披露此类机密信息。上述义务不适用于任何被指定为机密的信息，如果在发布此类信息之前，此类机密信息已为接收方所知，且接收方无保密义务；非因接收方的不当行为而为公众所知；接收方在未参考披露方机密信息的情况下独立开发；从第三方处获得，且无任何保密限制；披露方在无任何保密义务的情况下向第三方披露；或经披露方事先书面授权批准发布。 如果接收方收到根据法院命令、政府机构要求或其他法律程序提出的披露披露方机密信息的请求，接收方应及时通知披露方，并合理协助披露方获得保护令，该保护令要求必须披露的任何部分机密信息只能用于法院发布命令的目的或法律规定的其他目的。各合作伙伴应自行承担与此相关的法律费用',
    "key656": "陈述、免责声明和赔偿",
    "key657": "陈述与保证： (a) 各合作伙伴保证、陈述并承诺(i) 其拥有履行协议义务的全部权力和授权；(ii) 其签订和履行协议义务不会侵犯任何第三方的权利或导致其违反对第三方的任何义务；(iii) 其在履行协议义务时应完全遵守任何及所有适用法律、规则和规定。",
    "key658": '赔偿：每个合作伙伴（"补偿人"）应保护、补偿并使乐逗吧和其他合作伙伴及其各自的股东、董事、高级职员、雇员、分包商和代理人（统称为 "补偿人"）免受因补偿人违反本协议而由第三方产生、声称或承受的所有索赔、诉讼、费用、损害赔偿责任、支出（包括合理的律师费）、和解和判决（"索赔"）的损害，但前提是：(a) 受补偿人在收到任何索赔通知后的商业合理时间内书面通知补偿人；(b) 补偿人全权控制辩护和所有相关的和解谈判（但未经补偿人合理同意，补偿人不得以任何承认受补偿人过错的方式解决任何索赔）；以及 (c) 受补偿人向补偿人提供履行本节规定的义务所需的合理协助、信息和授权。受补偿人可参与索赔的辩护，费用由受补偿人承担，但这种参与不得免除或减少补偿人在本节项下的任何义务。',
    "key659": '责任限制： (a) 以下任何条款均不排除或限制法律规定不能排除或限制的责任（合称 "除外损失"）。(b) 除 "除外损失 "外，各方对任何其他方的合同、侵权（包括疏忽或违反法定义务、虚假陈述或其他）或任何其他普通法或法定诉讼理由或其他因本协议及各奖励计划而产生的或与之相关的责任，应仅限于乐捐吧根据奖励计划实际支付的报酬和/或任何其他费用。(c) 除除外损失外，任何一方均不因合同、侵权行为（包括疏忽）或其他原因对另一方承担以下责任：任何实际或潜在利润、合同或客户的损失；数据和承诺数据或其他恢复的损失；声誉损失；任何其他间接的、后果性的、特殊的、惩罚性的、惩戒性的损害赔偿（无论是否因疏忽、违约或其他原因引起）。',
    "key660": "一般情况",
    "key661": "放弃补救：PlaySaveFirst 在行使或执行本协议条款时的任何延迟都不得损害或限制 PlaySaveFirst 的权利，任何对其权利的放弃也不得视为对任何后续违约行为的放弃。任何一方在本协议中被授予或保留的权利、权力或补救措施，或法律规定的权利、权力或补救措施，均不排斥该合作伙伴可获得的任何其他权利、权力或补救措施，且每项权利、权力或补救措施均应是累积性的。",
    "key662": "转让：合作伙伴不得将其在本协议下的任何或所有权利和义务转让或分包给第三方。",
    "key663": "通知：本协议规定的所有通知均应以书面形式发出（电子邮件或其他电子方式即可）。",
    "key664": "完整协议：本协议代表双方对本协议主题的全部理解，并构成与本协议主题相关的全部协议，本协议取代与本协议主题相关的任何先前协议，并且只能由双方以书面形式签署进行修订。每一方承认并同意，除本协议明确规定的陈述或保证外，其并未依赖任何其他陈述或保证。",
    "key665": "电子签名：每一方承认并同意，通过点击接受本协议，即提交了经授权的电子签名，并签订了具有法律约束力的合同。此外，每一方特此放弃任何司法管辖区的任何适用法规、条例、规则、法令或其他法律规定的任何权利或要求，这些法律要求提供原始签名或交付或保留非电子记录，或以电子方式以外的方式付款或授信。",
    "key666": "可分割性：如果有管辖权的法院或行政机构认定本协议的任何条款（全部或部分）不合法、无效或根据任何颁布的法律或法律规则不可执行，则该条款或部分应被视为不构成本协议的一部分，本协议其余部分的可执行性和有效性不受影响。双方应本着诚意尝试修改任何无效条款或部分，以实现双方的既定意图。",
    "key667": "存续：根据其性质应合理解释为在本协议终止或期满后继续有效的所有条款在本协议终止或期满后继续有效。",
    "key668": "双方关系：合作伙伴之间的关系是独立承包商的关系，本协议并未在他们之间建立任何联合、合伙、合资或代理关系。任何一方均无权以任何方式约束另一方或使另一方承担责任。",
    "key669": "同意协议并激活",
    "key670": "修改密码",
    "key671": "我的推广链接",
    "key672": "默认链接",
    "key673": "复制链接",
    "key674": "我的促销收入",
    "key675": "结算金额",
    "key676": "累计金额",
    "key677": "待结算金额",
    "key678": "收入详情",
    "key679": "用户详细信息",
    "key680": "退出",
    "key681": "退出登录",
    "key682": "还没有收入记录",
    "key683": "您还没有账户？",
    "key684": "有账户吗？",
    "key685": "注册账户或登录即表示您同意以下条款",
    "key686": "登录并享受全面服务！",
    "key687": "输入完整的 confrimPassword",
    "key688": "两次输入的密码不一致！",
    "key689": "请输入您的电子邮件",
    "key690": "无效电子邮件",
    "key691": "请输入验证码",
    "key692": "输入完整密码",
    "key693":"获取验证码",
    "key694":"验证码已发送邮箱，该验证码10分钟内有效",
    "key695":"新密码",
    "key696":"请绑定您的电子邮件以接收重要信息，如交易信息、账户安全等。",
    "key697":"绑定邮箱",
    "key698":"要访问playsavefristofficial.com的更多服务，请绑定您的Google帐户。",
    
}

