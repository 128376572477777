
import Vue from 'vue'
import Vuex from 'vuex'
import axiosApi from '../utils/api'
import userAvatar from '../assets/image/user_white.png'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      dialogFlg:false,
      dialogContent:{},
      showDialog:false, /* login form */
      lang_flag:false, /* 語言 lialog */
      currency:'',
      lang:'',
      langOptions:[],
      currencyOptions:[],
      userInfo:'',
      token:'',
    },
   
    mutations: {
      setDialogFlg(state,value){
        state.dialogFlg = value
      },
      setDialogContent(state,value){
        state.dialogContent = value
      },
      setShowDialog(state,value){
        console.log('showDialog',value)
        state.showDialog = value
      },
      setLangOptions(state,value){
        state.langOptions = value
      },
      setCurrencyOptions(state,value){
        state.currencyOptions = value
      },
      setCurrency(state,value){
        state.currency = value
      },
      setLang(state,value){
        state.lang = value
      },
      setToken(state,value){
        state.token = value
      },
      removeToken(state,value){
        state.token = ''
      },
      setLang_flag(state,value){
        state.lang_flag = value
      },
      setUserInfo(state,value){
       
        state.userInfo=value
      }

    },
    actions:{
      getLanguage({commit},{dictType}){
        axiosApi.getDictType({dictType}).then(res=>{
          commit('setLangOptions',res.data)
          let item = res.data.find(v=>v.isDefault=="Y")
        
          if(item){
            commit('setLang',item)
            let lang = localStorage.getItem('lang')||''
            if(lang != item.dictDefaultLabel){
              localStorage.setItem('lang',item.dictDefaultLabel)
              // location.reload()
            }
          }
          else{
            res.data.map(v=>{
             if(v.dictSort ==1){
                commit('setLang',v)
                localStorage.setItem('lang',v.dictDefaultLabel)
                // location.reload()
              }
            })
          }
          
        })
      },
      getCurrency({commit},{dictType}){
        axiosApi.getDictType({dictType}).then(res=>{
          commit('setCurrencyOptions',res.data)
          res.data.map(v=>{
            if(v.dictSort ==1){
              commit('setCurrency',v)
            }
            if(v.isDefault == "Y"){
              commit('setCurrency',v)
              return
            }
          })  
        })
      },
      getUserInfo({commit}){
        axiosApi.getUserinfo().then(res=>{
          if(res.code==200){
           let image = res.data.image?res.data.image:userAvatar
           res.data.image = image
            commit('setUserInfo',res.data)
            return true
          }else{
            return false
          }
        })
      }
    }
  })
