
import cookies from "../utils/cookie";
export const thirdSign = {
    components:{},
    data(){
        return{
            GOOGLE_CLIENT_ID:'376327036552-5jl4mf4s86v2j3rgbo7eg96u2if0v9ks.apps.googleusercontent.com',
            thirdLog:{
                id:'',
                account:'',
                name:'',
                image:'',
                accessToken:'',
                type:'',
                inviteUserCode:'',  
                lastName:'',
                firstName:'',
            },
            logCate:[
                {icon:'icon-google',bg:'#f7f8fc',color:'#1f2128e6',name:'Google',type:1,class:'google_sigin'},
                // {icon:'icon-apple-fill',bg:'#000',color:'#fff',name:'Apple',type:7,class:''},
                {icon:'icon-14facebook',bg:'#1877f2',color:'#fff',name:'Facebook',type:2,class:''},
                {icon:'icon-vk',bg:'#07f',color:'#fff',name:'VK',type:5,class:''},
                {icon:'icon-email',bg:'#ffcb2e',color:'#1f2128e6',name:'Email',type:0,class:''},
                
                // {icon:'icon-line',bg:'#06c755',color:'#fff',name:'Line',type:3,class:''},
                // {icon:'icon-twiter',bg:'#1d9bf0',color:'#fff',name:'Twitter',type:4,class:''},
                // {icon:'icon-tiktok',bg:'#000',color:'#fff',name:'TikTok',type:6,class:''},
            ],
        }
    },
    created(){
       
    },
    mounted(){
        
    },
    computed:{},
    methods:{
        getGoogle(){
            const script = document.createElement("script");
            script.src = "https://accounts.google.com/gsi/client";
            document.body.appendChild(script);
            if(!window.google){
                console.log('no google')
                let _this = this
                setTimeout(()=>{
                    _this.getGoogle()
                },1000)
               return
            }
            // window.addEventListener("load", () => {
                console.log('load',google)
            window.google.accounts.id.initialize({       
                // 主要就是填写client_id
                client_id: this.GOOGLE_CLIENT_ID,
                auto_select: false,
                callback: this.handleCredentialResponse,
            });
            google.accounts.id.intermediate.notifyParentResize(470);
            // 设置按钮的样式等
            window.google.accounts.id.renderButton(
                document.getElementById("google_sigin"),
                {
                theme: "outline",
                size: "large",
                shape:'circle',
                width:'44px',
                height:'44px',
                innerHeight:'44px',
                type: "icon",
                logo_alignment:'right',
                text: "  ",
                },
               
            );
            window.google.accounts.id.renderButton(  
                document.getElementById("google_mobile_sigin"),
                {
                theme: "outline",
                size: "large",
                width:'344px',
                height:'50px',
                innerHeight:'50px',
                type: "standard",
                logo_alignment:'center',
                text: "signin_with",
                locale:'en'
                }
            );
            window.google.accounts.id.renderButton(  
                document.getElementById("google_bind_sigin"),
                {
                theme: "filled_blue",
                size: "large",
                width:'800px',
                maxWidth:'420px',
                max_width:'1000px',
                height:'80px',
                innerHeight:'80px',
                type: "standard",
                logo_alignment:'center',
                text: "signin_with",
                locale:'en',
               
                }
            );
           
        },
        async handleCredentialResponse(response) {
            // 获取回调响应的凭证数据 然后拿这个凭证给后台，jwt进行解析获取登录信息
            // await api.googleLogin(code);
            // const profile = jwt_decode(response.credential);  // 解析返回的信息
            // id = profile.sub; email = profile.email;  需要什么取什么，然后传给后端验证
            console.log("GOOGLE response:", response);
           this.thirdLog.type=1
           let accessToken = response.credential
           this.thirdSign(accessToken)
        
        },
        thirdSign(accessToken){
            let _this = this
            let {id,account,name,image,type,lastName,firstName} = this.thirdLog
            let inviteUserCode = this.inviteUserCode
            this.$axiosApi.threeLoginMes({id,account,name,image,accessToken,type,inviteUserCode,lastName,firstName}).then(res=>{
                if(res.code==200){
                    this.$message.success(res.msg)
                    cookies.setToken(res.data.data.token)
                    setTimeout(()=>{    
                        _this.$store.commit('setShowDialog',false)
                        location.reload()
                    },1000)
                }
            })
        },
        bindThreeAccount(accessToken){
            let _this = this
            let {id,account,name,image,type,lastName,firstName} = this.thirdLog
            let inviteUserCode = this.inviteUserCode
            this.$axiosApi.bindThreeAccount({id,account,name,image,accessToken,type,inviteUserCode,lastName,firstName}).then(res=>{
                if(res.code==200){
                    this.showDialog = false
                    this.bingThirdFlag = false
                    this.$message.success(res.msg)
                    this.getBindThird()
                }
            })
        },
        handleClick(item,bindthird){
            console.log('item.type',item)
            this.thirdLog.type = item.type
            if(item.name =='Email'){
                this.setSignType('login')
                return
            }
            // FB
            if(item.type ==2){
                console.log('fb',FB)
                this.thirdLog.type = item.type
                let _this = this
                FB.login(function(response){
                    console.log('response',response)
                    let accessToken = response.authResponse.accessToken
                    if(bindthird){
                        _this.bindThreeAccount(accessToken)
                        return
                    }
                    _this.thirdSign(accessToken)
                },{scope: 'public_profile,email'})
                // this.handleCredentialResponse()
            }
            // twitter
            if(item.type==4){
                hello.init({
                  'twitter': 'Ke1onjeIIyICIs5CB4HdPUw6c' ,// 这里设置Twitter Api key
                  
                },{
                    scope : 'email',
                    redirect_uri: 'http://localhost:8080'
                }
              );
                hello('twitter').login();
                // Listen to signin requests
                hello.on('auth.login', function(r) {
                    // Get Profile
                    hello( r.network ).api( '/me' ).then( function(p) {
                    window.console.log('hello',p) //输出用户信息
            
                    });
                });


            }
            //vk
            if(item.type==5){
                let _this = this
                 VK.init({apiId: '52794542'});
                 VK.Auth.login(function(response){
                    if(response.session){
                        // / *用户授权成功* /
                        console.log('VK',response)
                        if(response.status=='connected'){
                            // / *所选用户访问设置，如果他们被请求* /
                            let accessToken = response.session.sid
                            // _this.thirdLog.name = response.session.user.first_name+' '+response.session.user.last_name
                            _this.thirdLog.firstName = response.session.user.first_name
                            _this.thirdLog.lastName = response.session.user.last_name
                            _this.thirdLog.id = response.session.user.id
                            console.log('bindthird',bindthird)
                            if(bindthird){
                                _this.bindThreeAccount(accessToken)
                                return
                            }
                            _this.thirdSign(accessToken)
                        }
                    } else {
                        // / *用户单击授权窗口中的取消按钮* /
                    }
                });

            }
            // line
            if(item.type == 3){
                // this.openLineLoginIframe()
                let client_id = '2006595014'
                let redirectId = 'f558cadf352d05537875e7a0eec31f52'
                let redirect_uri='http://localhost:8080'
                const lineAuthorizeUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&state=12345abcde&scope=profile%20openid`;
                // window.location.href = lineAuthorizeUrl
                const popup = window.open(lineAuthorizeUrl, 'LINE - Login', 'width = 600, height = 600');
                const interval = setInterval(() => {
                if (popup.closed) {
                    clearInterval(interval);
                    const queryParams = new URLSearchParams(window.location.search);
                    const authorizationCode = queryParams.get('code');
                    console.log('queryParams',authorizationCode)
                    if (authorizationCode) {
                    // 进行获取令牌等后续操作，和之前类似
                        
                    }
                }
                }, 1000);
            }
            
            
        },
    }
}