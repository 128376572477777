<template>
  <div class="dialog" v-show="lang_flag" id="lang_dialog">
      <div class="dialog_container">
        <div class="dialog_group">
          <div class="dialog_title">{{$t('key5')}} & {{$t('key6')}}</div>
          <div class="dialog_option">
            <div class="option_label">{{$t('key5')}} </div>
            <div class="option_value" id="lang_select">
              <el-select v-model="lang" placeholder="">
                <el-option
                  v-for="item in langOptions"
                  :key="item.dictCode"
                  :label="item.name"
                  :value="item.dictDefaultLabel">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="dialog_option">
            <div class="option_label">{{$t('key6')}}</div>
            <div class="option_value">
              <el-select v-model="currency" placeholder="">
                <el-option
                  v-for="item in currencyOptions"
                  :key="item.dictValue"
                  :label="item.dictDefaultLabel"
                  :value="item.dictDefaultLabel">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="dialog_text">{{$t('key7')}}</div>
          <div class="dialog_btn" @click="setDict">{{$t('key8')}}</div>
        </div>
        <div class="btn_close iconfont icon-close" @click="setLang()"></div>
      </div>
    </div>
</template>

<script>


export default {
    data(){
        return{
            lang:'',
            // langOptions:[
            //     {value:'en',label:'English',id:'1'},{value:'zh',label:'中文',id:'4'},{value:'id',label:'IndonesiaName',id:'2'}, {value:'ja',label:'日本語',id:'3'},       
            // ],
           currency:'',
            // curOptions:[
            //   {value:'$',label:'$ USD',id:'1'},{value:'¥',label:'¥ JPY',id:'2'},{value:'Rp',label:'Rp IDR',id:'3'},{value:'¥',label:'¥ CNY',id:'2'}
            // ],
            
        }
    },
    methods:{
        setLang(){
            this.$store.commit('setLang_flag',false)
            // [
            //     {dictLabel:'EN',dictDefaultLabel:'en',name:'English',},{dictLabel:'IN',dictDefaultLabel:'id',name:'IndonesiaName',},{dictLabel:'JP',dictDefaultLabel:'ja',name:'日本語',},{dictLabel:'GTF',dictDefaultLabel:'zh',name:'中文',},{dictLabel:'KR',dictDefaultLabel:'kr',name:'한국어',}
            // ],
            // [
            //    {dictLabel:'USD',dictDefaultLabel:'$ USD'},{dictLabel:'JPY',dictDefaultLabel:'¥ JPY'},{dictLabel:'IDR',dictDefaultLabel:'Rp IDR'},
            // ]
        },
        setDict(){
            let lang = this.lang
            let currency = this.currency
            let lingo = ''
            let linCur = ''
            this.langOptions.map(v=>{
                if(v.dictDefaultLabel == lang){
                   
                    lingo = v.dictCode
                    
                }
            })
            this.currencyOptions.map(c=>{
                if(c.dictDefaultLabel == currency){
                    console.log(' this.currencyOptions',currency,c, this.currencyOptions)
                    linCur = c.dictCode
                   
                }
            })
            this.$axiosApi.setDictType({lingo,currency:linCur}).then(res=>{
                if(res.code == 200){
                    localStorage.setItem('lang',lang)
                    location.reload()
                }
            })
            
        }
    },
    created(){
        let lang = this.$store.state.lang
        let currency = this.$store.state.currency
        this.lang = lang.dictDefaultLabel
        this.currency = currency.dictDefaultLabel
    },
    computed:{
      lang_flag(){return this.$store.state.lang_flag},
      langOptions(){return this.$store.state.langOptions},
      currencyOptions(){ return this.$store.state.currencyOptions},
  
    }
}
</script>

<style lang="scss" scoped>
.dialog{
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background:#0000008f;
    z-index: 999;
    .dialog_container{
      padding:16px 72px;
      width: 600px;
      max-width: 100%;
      margin:0 auto;
      position: relative;
      top:25%;
      
      .dialog_group{
        
        background:#fff;
        border-radius: 6px;
        text-align: left;
        width: 100%;
        padding:24px 16px;
        .dialog_title{
          color: #1f2128;
          margin-bottom: 16px;
          font-size: 24px;
          font-weight: 600;
        }
        .dialog_option{
          .option_label{
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 16px;

          }
          .option_value{
            width: 100%;
            box-sizing:content-box;
            border: 1px solid transparent;
            border-radius: 4px;
            &:hover{
              border:1px solid #ffd633;
            }
            .el-select{
              display: block;
              .el-input__inner{
                height: 48px;
                line-height: 48px;
                background:#eff2f5;
                border:none;
                
              }
            }
          }
          
        }
         .dialog_text{
            font-size: 14px;
            line-height: 20px;
            margin-top: 12px;
            color:#77808c;
          }
          .dialog_btn{
            width: 100%;
            height: 48px;
            font-size: 16px;
            line-height: 48px;
            margin-top: 40px;
            font-weight: 700;
            cursor: pointer;
            background:#ffcb2e;
            border-radius: 6px;
            text-align: center;
            &:hover{
              opacity: 0.7;
            }
          }
      }
      .btn_close{
        background:#fff;
        color:#0f0f0f;
        font-size: 24px;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        position: absolute;
        top:16px;
        right:10px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        &:hover{
          opacity: 0.7;
        }
      }
      @media screen and (max-width:768px){
        .btn_close{
          top:430px!important;
          left:50%;
          transform: translate(-50%);
        }
      }
    }

  }
</style>